export async function getLeftMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'icmn icmn-stats-dots',
      children: [
        {
          key: 'overviewStatistics',
          title: 'Overview Statistics',
          url: '/dashboard/overview-statistics',
        },
        {
          key: 'userDemographics',
          title: 'User Demographics',
          target: '_blank',
          url: 'https://lookerstudio.google.com/reporting/d6f08158-2738-40f1-9cb6-71374355d42e',
        },
        {
          key: 'audienceStatistics',
          title: 'Audience Statistics',
          url: '/dashboard/audience-statistics',
        },
        {
          key: 'videoStatistics',
          title: 'Video Statistics',
          url: '/dashboard/video-statistics',
        },
        {
          key: 'contentStatistics',
          title: 'Content Statistics',
          url: '/dashboard/content-statistics',
        },
        {
          key: 'linearChannelStatistics',
          title: 'Linear Channel Statistics',
          url: '/dashboard/linear-channel-statistics',
        },
        {
          key: 'downloadStatistics',
          title: 'Download Statistics',
          url: '/dashboard/download-statistics',
        },
        // {
        //   key: 'healthPanel',
        //   title: 'Health Panel',
        //   url: '/dashboard/health-panel',
        // },
      ],
    },
    {
      title: 'Notifications',
      key: 'notifications',
      icon: 'icmn icmn-newspaper',
      url: '/notifications',
    },
    {
      title: 'Content',
      key: 'content',
      icon: 'icmn icmn-video-camera',
      children: [
        {
          title: 'Contents',
          key: 'contents',
          icon: 'icmn icmn-profile',
          children: [
            {
              key: 'allContents',
              title: 'All Contents',
              url: '/contents',
            },
            {
              key: 'addContents',
              title: 'Add Contents',
              url: '/contents/add-content',
            },
            {
              key: 'uploadContent',
              title: 'Bulk Content Import',
              url: '/contents/bulk-content-import',
            },
            {
              title: 'Territory Tool',
              key: 'territoryTool',
              url: '/contents/territory-tool'
            },
          ],
        },
        {
          title: 'Similar Titles',
          key: 'similarTitles',
          icon: 'icmn icmn-embed',
          inline: true,
          children: [
            {
              key: 'allSimilarTitles',
              title: 'All Similar Titles',
              url: '/similar-titles',
            },
            {
              key: 'addSimilarTitles',
              title: 'Add Similar Titles',
              url: '/similar-titles/add-similar-title',
            },
          ],
        },
        {
          title: 'Tags',
          key: 'tags',
          icon: 'icmn icmn-price-tag',
          inline: true,
          children: [
            {
              key: 'allTag',
              title: 'All Tag',
              url: '/tags',
            },
            {
              key: 'addTag',
              title: 'Add Tag',
              url: '/tags/add-tag',
            },
          ],
        },
      ],
    },
    {
      title: 'Smart Adbreak Points',
      key: 'spotzJobs',
      icon: 'icmn icmn-profile',
      children: [
        {
          key: 'allSmartAdBreakPoint',
          title: 'All Jobs',
          url: '/smart-adbreak-points',
        },
        {
          key: 'addSmartAdBreakPoint',
          title: 'Start a new job',
          url: '/smart-adbreak-points/start-analysis-video',
        },
      ],
    },
    {
      title: 'Promotions',
      key: 'promotions',
      icon: 'icmn icmn-newspaper',
      children: [
        {
          key: 'allPromotions',
          title: 'All Promotions',
          url: '/promotions',
        },
        {
          key: 'addPromotions',
          title: 'Add Promotions',
          url: '/promotions/add-promotion',
        },
      ],
    },
    {
      title: 'Programming',
      key: 'programming',
      icon: 'icmn icmn-table2',
      children: [
        {
          title: 'Homepage Playlists',
          key: 'groups',
          icon: 'icmn icmn-images',
          children: [
            {
              key: 'allGroups',
              title: 'All Home page Playlist',
              url: '/groups',
            },
            {
              key: 'addGroups',
              title: 'Add Playlist',
              url: '/groups/add-group',
            },
          ],
        },
        {
          title: 'Channels',
          key: 'channels',
          icon: 'icmn icmn-tv',
          children: [
            {
              key: 'allChannels',
              title: 'All Channels',
              url: '/channels',
            },
            {
              key: 'addChannel',
              title: 'Add Channel',
              url: '/channels/add-channel',
            },
          ],
        },
        {
          title: 'External Channels',
          key: 'externalChannels',
          icon: 'icmn icmn-rss',
          children: [
            {
              key: 'allExternalChannels',
              title: 'All External Channels',
              url: '/external-channels',
            },
            {
              key: 'importExternalChannel',
              title: 'Parse and Import XML Feed',
              url: '/external-channels/import-external-channel',
            },
          ],
        },
      ],
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'icmn icmn-user',
      children: [
        {
          key: 'allUsers',
          title: 'All Users',
          url: '/users',
        },
        {
          key: 'addUsers',
          title: 'Add User',
          url: '/users/add-user',
        },
        {
          title: 'Contact Form',
          key: 'formData',
          icon: 'fa fa-life-ring',
          url: '/form-data',
          children: [
            {
              title: 'General Form',
              key: 'generalForm',
              url: '/form-data/general-form',
            },
            {
              title: 'Technical Form',
              key: 'technicalForm',
              url: '/form-data/technical-form',
            },
          ],
        },
      ],
    },
    {
      title: 'Monitoring & Logs',
      key: 'logs',
      icon: 'icmn icmn-files-empty',
      children: [
        {
          title: 'Activity Logs',
          key: 'allHistory',
          icon: 'icmn icmn-history',
          url: '/history',
        },
        {
          title: 'System Logs',
          key: 'allLog',
          icon: 'icmn icmn-file-text2',
          url: '/log',
        },
        {
          title: 'Video Processing',
          key: 'transcodes',
          icon: 'icmn icmn-film',
          url: '/transcodes',
        }
      ]
    },
    {
      title: 'Advertising',
      key: 'advertising',
      icon: 'icmn icmn-bullhorn',
      children: [
        {
          title: 'Publica',
          key: 'publica',
          url: '/publica',
        },
        {
          title: 'SSAI Vast Tags',
          key: 'vdmsTags',
          children: [
            {
              key: 'allVDMSTags',
              title: 'All VDMS Tags',
              url: '/vdms-tags',
            },
            {
              key: 'addVDMSTags',
              title: 'Add VDMS Tags',
              url: '/vdms-tags/add-vdms-tag',
            },
          ],
        },
        {
          title: 'CSAI Vast Tags (inactive)',
          key: 'vastTags',
          inline: true,
          children: [
            {
              key: 'allVastTags',
              title: 'All Vast Tags',
              url: '/vast-tags',
            },
            {
              key: 'addVastTags',
              title: 'Add Vast Tags',
              url: '/vast-tags/add-vast-tag',
            },
          ],
        },
        {
          title: 'ads.txt - app-ads.txt',
          key: 'vastTags',
          url: '/txt',
        },
      ]
    },
    {
      title: 'Tab Management',
      key: 'tabManagement',
      icon: 'icmn icmn-bullhorn',
      url: '/tab-management',
    },
    {
      title: 'Video Pipeline (Remezcla)',
      key: 'videoPipeline',
      icon: 'icmn icmn-bullhorn',
      children: [
        {
          title: 'Job Overview',
          key: 'jobOverview',
          url: '/video-pipeline/job-overview',
        },
        {
          title: 'New Video Task',
          key: 'newVideoTask',
          url: '/video-pipeline/new-video-task',
        },
      ]
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog',
      children: [
        {
          title: 'Setting',
          key: 'setting',
          icon: 'icmn icmn-cog',
          url: '/settings/setting',
        },
        {
          title: 'Links',
          key: 'links',
          icon: 'icmn icmn-link',
          inline: true,
          children: [
            {
              title: 'All Links',
              key: 'allLinks',
              url: '/links',
            },
            {
              title: 'Add Links',
              key: 'addLinks',
              url: '/links/add-link',
            },
          ],
        },
        {
          title: 'Pages',
          key: 'pages',
          icon: 'icmn icmn-book',
          inline: true,
          children: [
            {
              title: 'All Pages',
              key: 'allPages',
              url: '/pages',
            },
            {
              title: 'Add Pages',
              key: 'addPages',
              url: '/pages/add-page',
            },
          ],
        },
        {
          title: 'Contract',
          key: 'contract',
          icon: 'icmn icmn-user-tie',
          url: '/settings/contract',
          children: [
            {
              title: 'Rights',
              key: 'rights',
              inline: true,
              children: [
                {
                  key: 'allRights',
                  title: 'All Rights',
                  url: '/rights',
                },
                {
                  key: 'addRights',
                  title: 'Add Rights',
                  url: '/rights/add-right',
                },
              ],
            },
            {
              title: 'Contract Territories',
              key: 'contractTerritories',
              inline: true,
              children: [
                {
                  key: 'allContractTerritories',
                  title: 'All Contract Territories',
                  url: '/contract-territories',
                },
                {
                  key: 'addContractTerritories',
                  title: 'Add Contract Territories',
                  url: '/contract-territories/add-contract-territory',
                },
              ],
            },
            {
              title: 'Contract Templates',
              key: 'contractTemplates',
              inline: true,
              children: [
                {
                  key: 'allContractTemplates',
                  title: 'All Contract Templates',
                  url: '/contract-templates',
                },
                {
                  key: 'addContractTemplates',
                  title: 'Add Contract Templates',
                  url: '/contract-templates/add-contract-template',
                },
              ],
            },
          ],
        },
        {
          title: 'Genres',
          key: 'genres',
          icon: 'icmn icmn-menu',
          inline: true,
          children: [
            {
              key: 'allGenres',
              title: 'All Genres',
              url: '/genres',
            },
            {
              key: 'addGenres',
              title: 'Add Genres',
              url: '/genres/add-genre',
            },
          ],
        },
        {
          title: 'Third Party Platforms',
          key: 'thirdPartyPlatforms',
          icon: 'icmn icmn-office',
          inline: true,
          children: [
            {
              key: 'allThirdPartyPlatforms',
              title: 'All Third Party Platforms',
              url: '/platforms',
            },
            {
              key: 'addThirdPartyPlatform',
              title: 'Add Third Party Platform',
              url: '/platforms/add-platform',
            },
          ],
        },
        {
          title: 'Countries',
          key: 'countries',
          icon: 'icmn icmn-earth',
          inline: true,
          children: [
            {
              key: 'allCountries',
              title: 'All Countries',
              url: '/countries',
            },
            {
              key: 'addCountries',
              title: 'Add Countries',
              url: '/countries/add-country',
            },
          ],
        },
        {
          title: "Right's Holders",
          key: 'rightsHolders',
          icon: 'icmn icmn-users',
          inline: true,
          children: [
            {
              key: 'allRightsHolders',
              title: 'All Rights Holders',
              url: '/rights-holders',
            },
            {
              key: 'addRightsHolders',
              title: 'Add Rights Holders',
              url: '/rights-holders/add-rights-holder',
            },
            {
              title: "Right's Holder Groups",
              key: 'rightsHolderGroups',
              inline: true,
              children: [
                {
                  key: 'allRightsHolderGroups',
                  title: 'All Rights Holder Groups',
                  url: '/rights-holder-groups',
                },
                {
                  key: 'addRightsHolderGroups',
                  title: 'Add Rights Holder Groups',
                  url: '/rights-holder-groups/add-rights-holder-group',
                },
              ],
            },
          ],
        },

        {
          title: 'Roles',
          key: 'roles',
          icon: 'icmn icmn-user-check',
          inline: true,
          children: [
            {
              key: 'allRoles',
              title: 'All Roles',
              url: '/roles',
            },
            {
              key: 'addRoles',
              title: 'Add Roles',
              url: '/roles/add-role',
            },
          ],
        },
        {
          title: 'MRSS Feeds',
          key: 'mrssFeeds',
          icon: 'icmn icmn-menu',
          inline: true,
          children: [
            {
              key: 'allFeeds',
              title: 'All Feeds',
              url: '/mrss-feeds',
            },
            {
              key: 'addFeeds',
              title: 'Add Feeds',
              url: '/mrss-feeds/add-mrss-feed',
            },
          ],
        },
        {
          title: 'Reporting',
          key: 'reporting',
          icon: 'icmn icmn-calculator',
          children: [
            {
              key: 'tool',
              title: 'Tool',
              url: '/reporting/tool',
            },
            {
              key: 'manuallyAdd',
              title: 'Manually Add',
              url: '/reporting/manually-add',
            },
            {
              key: 'uploadReport',
              title: 'Upload Report',
              url: '/reporting/upload-report',
            },
            {
              key: 'deleteReport',
              title: 'Delete Report',
              url: '/reporting/delete-report',
            },
            {
              key: 'autoFillBlankTitleCsv',
              title: 'Auto Fill Blank Title CSV',
              url: '/reporting/auto-fill-blank-title-csv',
            },
          ],
        },
        {
          title: 'Edgecast Cleanup',
          key: 'edgecastCleanup',
          icon: 'icmn icmn-bin',
          children: [
            {
              key: 'vdmsVideos',
              title: 'VDMS Videos',
              url: '/tools/vdms-videos',
            },
          ],
        },
      ],
    },
  ];
}
export async function getTopMenuData() {
  return [];
}
